body {
  margin: 0;
  padding: 0;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}


.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.establishments {
  display: flex;
  flex-wrap: wrap;
}

.header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 1rem 0;

  @media (min-width: 560px) {
    justify-content: space-between;
  }
}

.tableHeader {
  input {
    max-width: 250px;
    margin-left: 15px;
  }
}

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.reporting-table table td:nth-child(-n+11),
  .reporting-table table th:nth-child(-n+11){
  background-color: #eeffe2;
}

.reporting-table table td:nth-child(n+12):nth-child(-n+28),
  .reporting-table table th:nth-child(n+12):nth-child(-n+28){
  background-color: #faf3dd;
}
.reporting-table table td:nth-child(n+29):nth-child(-n+32),
  .reporting-table table th:nth-child(n+29):nth-child(-n+32){
  background-color: #e5e5e5;
}
.reporting-table table td:nth-child(n+33):nth-child(-n+49),
  .reporting-table table th:nth-child(n+33):nth-child(-n+49){
  background-color: #ffe7a5;
}
.reporting-table table td:nth-child(n+50),
  .reporting-table table th:nth-child(n+50){
  background-color: #e4edff;
}
.select-activations {
  min-width: 300px;
}
.activation-filters .tw-filter-search {
  display: flex;
  max-height: 38px;
  margin-top: 15px;
  .tw-filter-input {
    margin: 0 auto;
  }
}