
.container {
  border : solid #1054bc 2px;
  margin:10px;
  padding: -20px;
  background-color: whitesmoke;
  width: 500px !important;
  height:96%;
  margin-top: 20px !important;
  
}
.middle {
    border : solid #1054bc 2px;
    background-color: whitesmoke;
    margin-top: 20px;
    width: 600px;
    margin-left: 12px;
  
    
}
.link {
    margin-top: 10px;
}
.polar {
  border : solid #1054bc 2px;
  padding: -20px;
  background-color: whitesmoke;
  width: 500px;
  margin-left:60px !important;
  margin-top: 10px;
}
.last {
  border : solid #1054bc 2px;
  padding: -20px;
  background-color: whitesmoke;
  width: 600px;
  height: 590px;
  margin-top:10px;
  margin-left:10px;
}
